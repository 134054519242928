<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <sold-items-transactions-searcher></sold-items-transactions-searcher>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <sold-items-transactions-search-result></sold-items-transactions-search-result>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import SoldItemsTransactionsSearcher from "@/components/transactions/SoldItemsTransactionsSearcher.vue";
import SoldItemsTransactionsSearchResult from "@/components/transactions/SoldItemsTransactionsSearchResult.vue";

export default {
  components: {
    SoldItemsTransactionsSearcher,
    SoldItemsTransactionsSearchResult
  },
  mounted() {
    this.$store.commit("soldItemsTransactions/clear");
  }
};
</script>
