<style>
.checkbox {
  text-align: center;
}
</style>

<template>
  <b-overlay :show="$store.state.transactions.isLoading">
    <b-row class="mb-3">
      <b-col>
        <b-button @click="openCloseTransactionsModal" :disabled="!anyFilteredTransactionsSelected">
          {{ $t("transactions.sold-items-result.close-btn-caption") }}
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-pagination
          :value="pagination.currentPage"
          :total-rows="pagination.totalCount"
          :per-page="pagination.limit"
          @input="onChangeCurrentPage($event)"
        ></b-pagination>
        <b-table
          show-empty
          :fields="fields"
          :items="$store.getters['soldItemsTransactions/transactions']"
          @head-clicked="onTableHeadClicked"
          @sort-changed="onSortChanged"
          :empty-text="$t('transactions.sold-items-result.table.empty-text')"
          responsive
          no-local-sorting
        >
          <template slot="top-row">
            <td></td>
            <td>
              <b-input :value="filters.driver" @change="onChangeFilter('driver', $event)"></b-input>
            </td>
            <td>
              <b-input :value="filters.count" @change="onChangeFilter('count', $event)"></b-input>
            </td>
          </template>
          <template v-slot:cell(selected)="row">
            <b-form-checkbox
              class="checkbox"
              size="lg"
              v-model="row.item.selected"
              @input="onSelectTransaction($event, row.item.driver.id)"
            ></b-form-checkbox>
          </template>
          <template v-slot:cell(driver)="row">
            <b-link @click="openDriverTransactionsModal(row.item.driver.id)">{{ row.item.driver.userName }}</b-link>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-modal
      id="close-transactions-modal"
      @ok.prevent="closeTransactions"
      :title="$t('transactions.sold-items-result.modal.title')"
      :ok-title="$t('transactions.sold-items-result.modal.ok-btn-caption')"
      :cancel-title="$t('transactions.sold-items-result.modal.cancel-btn-caption')"
    >
      <template>
        {{ $t("transactions.sold-items-result.modal.body") }}
      </template>
    </b-modal>
    <b-modal
      id="driver-transactions-modal"
      :title="$t('transactions.sold-items-result.transactions-by-driver-modal.title')"
      :ok-title="$t('transactions.sold-items-result.transactions-by-driver-modal.ok-btn-caption')"
      :cancel-title="$t('transactions.sold-items-result.transactions-by-driver-modal.cancel-btn-caption')"
      @ok.prevent="hideDriverTransactionsModal"
      @hidden="transactions = []"
    >
      <template v-slot:default>
        <b-table :fields="driverTransactionFileds" :items="transactions" responsive>
          <template v-slot:cell(selected)="row">
            <b-form-checkbox v-model="row.item.selected"></b-form-checkbox>
          </template>
          <template v-slot:cell(createdAt)="row">{{ $d(new Date(row.item.createdAt)) }}</template>
          <template v-slot:cell(vehicle)="row">{{ row.item.vehicle.registrationNumber }}</template>
        </b-table>
      </template>
    </b-modal>
  </b-overlay>
</template>

<script>
import api from "@/services/api.js";

export default {
  data() {
    return {
      selectAll: false,
      transactions: [],
      transactionsToCloseIds: []
    };
  },
  computed: {
    selectedFilteredTransactions() {
      return this.$store.getters["soldItemsTransactions/transactions"].filter(x => x.selected);
    },
    anyFilteredTransactionsSelected() {
      return this.selectedFilteredTransactions.length > 0;
    },
    fields() {
      return [
        { key: "selected", label: "", sortable: false },
        { key: "driver", label: this.$t("transactions.sold-items-result.table.driver"), sortable: true },
        { key: "sold", label: this.$t("transactions.sold-items-result.table.sold"), sortable: true }
      ];
    },
    driverTransactionFileds() {
      return [
        { key: "selected", label: "", sortable: false },
        {
          key: "createdAt",
          label: this.$t("transactions.sold-items-result.transactions-by-driver-modal.createdAt"),
          sortable: true
        },
        {
          key: "sold",
          label: this.$t("transactions.sold-items-result.transactions-by-driver-modal.sold"),
          sortable: true
        },
        { key: "vehicle", label: this.$t("transactions.sold-items-result.transactions-by-driver-modal.vehicle") }
      ];
    },
    filters() {
      return this.$store.getters["soldItemsTransactions/filters"];
    },
    pagination() {
      return this.$store.getters["soldItemsTransactions/pagination"];
    }
  },
  methods: {
    onTableHeadClicked(column) {
      if (column == "selected") {
        this.selectAll = !this.selectAll;
        this.$store.commit("soldItemsTransactions/setSelectedStateByDriver", {
          driverIds: this.$store.getters["soldItemsTransactions/transactions"].map(x => x.driver.id),
          isSelected: this.selectAll
        });
      }
    },
    onSelectTransaction(evt, id) {
      this.$store.commit("soldItemsTransactions/setSelectedStateByDriver", {
        driverIds: [id],
        isSelected: evt
      });
    },
    openCloseTransactionsModal() {
      this.transactionsToCloseIds = this.selectedFilteredTransactions.map(x => x.transactions.map(t => t.id)).flat();
      this.$bvModal.show("close-transactions-modal");
    },
    closeTransactions() {
      api
        .closeTransactions(this.transactionsToCloseIds)
        .then(() => {
          this.$store.dispatch("soldItemsTransactions/loadTransactions");
          this.$bvModal.hide("close-transactions-modal");
          this.$bvModal.hide("driver-transactions-modal");
          this.transactionsToCloseIds = [];
        })
        .catch(() => alert(this.$t("transactions.sold-items-result.error.general")));
    },
    openDriverTransactionsModal(driverId) {
      this.transactions = this.$store.getters["soldItemsTransactions/transactionsByDriver"](driverId).map(x => {
        return {
          ...x,
          selected: false
        };
      });
      this.$bvModal.show("driver-transactions-modal");
    },
    hideDriverTransactionsModal() {
      let selectedTransactionIds = this.transactions.filter(x => x.selected).map(x => x.id);
      if (selectedTransactionIds.length === 0) {
        alert(this.$t("transactions.sold-items-result.transactions-by-driver-modal.error.not-selected"));
        return;
      }
      this.transactionsToCloseIds = selectedTransactionIds;
      this.$bvModal.show("close-transactions-modal");
    },
    onChangeFilter(property, value) {
      this.$store.dispatch("soldItemsTransactions/setFilter", { property, value });
    },
    onChangeCurrentPage(page) {
      this.$store.dispatch("soldItemsTransactions/setCurrentPage", page);
    },
    onSortChanged(ctx) {
      this.$store.dispatch("soldItemsTransactions/setSort", ctx);
    }
  }
};
</script>
